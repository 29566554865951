import { Outlet } from "react-router-dom";
import LeftSideBar from "../defaultLayout/leftSideBar/LeftSideBar";
import DashboardHeader from "../components/dashboardHeader";

const LayOut: React.FC = () => {
  return (
      <div className={`flex`}>
        <LeftSideBar />
        <div className="main-wrapper test w-full overflow-x-hidden">
          <DashboardHeader />
          <div className="site-content sm:overflow-y-auto overflow-y-clip sm:max-h-[calc(100vh-85px)] scroll-design-main ">
            <Outlet />
          </div>
        </div>
      </div>
  );
};

export default LayOut;
