import { useEffect, useRef, useState } from "react";


export const useDebounce = (value: string, delay = 500): string => {
    const [debouncedValue, setDebouncedValue] = useState("");
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return debouncedValue;
};
