import { Link } from "react-router-dom";
import { useBreadCrumbs } from "../../context/breadcrumb.context";

const BreadCrumbs = () => {
  //BreadCrumbs Context
  const { breadCrumbs } = useBreadCrumbs();

  return (
    <div>
      <ol
        className="sm:flex hidden items-center whitespace-nowrap min-w-0"
        aria-label="Breadcrumb"
      >
        {breadCrumbs?.map((crumb, index: number) => {
          const isLastBreadCrumb = index === breadCrumbs.length - 1;
          return (
            <li
              className="flex items-center text-sm capitalize first:text-gray-800 last:font-semibold group "
              key={crumb.link}
            >
              {isLastBreadCrumb ? (
                crumb.name
              ) : (
                <Link to={crumb.link}>{crumb.name}</Link>
              )}
              <svg
                className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400 group-last:hidden "
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </svg>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default BreadCrumbs;
