import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  ActiveTabSelector,
  AdminSidebarSelector,
  hideAdminSidebar,
  setActiveTab,
  showAdminSidebar,
} from "../../redux/slices/adminSidebarSlice";
import LinkContent from "../../components/leftSideBar/LinkContent";
import { RoutesPath } from "../../router/routes/Routers";
import { MenuList } from "../../constants/DropDownConstants";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import arrowRight from "../../assets/images/arrowRight.svg";
import logo from "../../assets/images/Paddle_png.png";

const AdminSideBar: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const [activatedSubMenus, setActivatedSubMenus] = useState<string[]>([]);

  const adminSidebar = useSelector(AdminSidebarSelector);
  const activeTab = useSelector(ActiveTabSelector);

  const handleSubMenuCss = (name: string) => {
    let result = "";
    if (activatedSubMenus.includes(name)) {
      return result;
    } else if (adminSidebar) {
      result = "hidden";
      return result;
    } else {
      return result;
    }
  };

  function renderSubMenuLink(el: { id: string; name: string; link: string }) {
    const isActive = activeTab === el.link;

    function getLinkClassName(isActive: boolean) {
      return `block font-semibold transition-all duration-300 py-3 ${
        isActive
          ? " before:content[''] relative before:absolute before:w-1 before:h-5 before:top-3 before:bg-primary before:left-0 before:rounded-lg pl-3 "
          : "text-grayDark hover:text-black  pl-3 "
      } ${adminSidebar ? "" : " text-left  "}`;
    }

    function handleLinkClick(link: string) {
      dispatch(setActiveTab(link));
    }

    return (
      <li className="" key={el?.id}>
        <Link
          className={getLinkClassName(isActive)}
          to={el.link}
          onClick={() => handleLinkClick(el.link)}
        >
          {el.name}
        </Link>
      </li>
    );
  }

  useEffect(() => {
    if (location.pathname === "/user") {
      dispatch(setActiveTab("/user"));
    } else if (location.pathname === "/business") {
      dispatch(setActiveTab("/business"));
    } else if (location.pathname === "/post") {
      dispatch(setActiveTab("/post"));
    }
    if (RoutesPath) {
      RoutesPath.forEach((route) => {
        if (route.path === location.pathname) {
          dispatch(setActiveTab(route.path));
        }
      });
    }
  }, [location.pathname]);

  const handleActivatedSubMenus = (name: string) => {
    const index = activatedSubMenus.indexOf(name);
    if (index === -1) {
      activatedSubMenus.push(name);
    } else {
      activatedSubMenus.splice(index, 1);
    }
    setActivatedSubMenus(activatedSubMenus);
  };

  return (
    <div
      className={` border-r border-[#E6E6E6] transition-all h-screen duration-300 transform top-0 left-0 bottom-0 z-[60]  bg-white  pt-7 pb-10 sticky  ${
        adminSidebar === true ? " w-72 " : "   w-32 "
      } `}
    >
      <div className="px-3 mb-4 text-center pb-5 ">
        <Link
          className="flex-none text-xl font-semibold mb-6 inline-block mx-auto"
          to="/"
          aria-label="Brand"
        >
          {/* PADDLE */}
          <img
            src={logo}
            className={` duration-150 ${
              adminSidebar === true ? " max-w-[120px] " : "   max-w-[85px] "
            } `}
            alt=""
          />
        </Link>
        <span
          onClick={() => {
            if (adminSidebar) dispatch(hideAdminSidebar());
            else dispatch(showAdminSidebar());
          }}
          className="sidebar__trigger stroke-black w-7 h-7 flex items-center justify-center rounded-full bg-primary border border-borderIcon fixed  z-[999] top-6 
            -right-3 "
        >
          {adminSidebar ? (
            <img src={arrowLeft} alt="left arrow" />
          ) : (
            <img src={arrowRight} alt="right arrow" />
          )}
        </span>
        <div>
          <ul className="sidebar-ul space-y-1.5 px-4  max-h-screen scroll-design ">
            {MenuList.map((e) => (
              <Fragment key={e.name}>
                {
                  <div>
                    <li
                      className={`  ${
                        activeTab === e.link
                          ? "active bg-navLinkHover text-primary stroke-primary before:content[''] relative before:absolute before:w-1 before:h-7 before:top-3 before:bg-primary before:left-0 before:rounded-r-lg rounded-lg cursor-pointer text-base group nested-menu "
                          : " "
                      } ${adminSidebar ? " " : " nested-menu "}`}
                    >
                      <Link
                        to={e.link}
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => {
                          if (e.name === "CMS") {
                            event.preventDefault();
                          }

                          if (activeSubMenu !== e.name && e.subMenu)
                            setActiveSubMenu(e.name);
                          else setActiveSubMenu(null);
                          handleActivatedSubMenus(e.name);
                          !e.subMenu && dispatch(setActiveTab(e.link));
                        }}
                        className="flex items-center gap-x-1.5 py-4 px-4 bg-transparent text-sm text-primary font-normal active  before:content[''] "
                      >
                        <LinkContent
                          e={e}
                          adminSidebar={adminSidebar}
                          activeTab={activeTab}
                          toggle={activeSubMenu === e.name ? e.name : null}
                          setToggle={setActiveSubMenu}
                        />
                      </Link>
                      {e.subMenu ? (
                        <div
                          className={
                            ` 
                        ${adminSidebar ? " " : ""}
                        ` + `${handleSubMenuCss(e?.name)}`
                          }
                        >
                          <ul
                            className={`${
                              adminSidebar ? "pl-5 text-left" : ""
                            } `}
                          >
                            {e?.subMenuList?.map(
                              (el: {
                                id: string;
                                name: string;
                                link: string;
                              }) => renderSubMenuLink(el)
                            )}
                          </ul>
                        </div>
                      ) : null}
                    </li>
                  </div>
                }
              </Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
