import { axiosGet, axiosPut } from "../axios/axios"

const prefix = "/notification"

// mark all as read
export const MarkAllAsRead = () => {
    return axiosPut(`${prefix}/all`, {
        isRead: true,
    })
}

// update notification unread to read
export const UpdateNotification = (id: number) => {
    return axiosPut(`${prefix}/${id}`, { isRead: true })
}

// get all unread notification count
export const GetAllUnreadNotificationCount = (query: string) => {
    return axiosGet(`${prefix}/all${query}`)
}

// get all Notification
export const GetAllNotification = (query: string) => {
    return axiosGet(`${prefix}/all${query}`)
}