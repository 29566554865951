import CardAttendance from "../../components/Business/cardAttendance";
import profile from "../../assets/images/avatar-boy.svg";
import { useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { ViewUserById } from "../../services/UserService";
import {
  ApproveTimesheet,
  GetEmployeeTimesheet,
  GetEmployeeanalytics,
} from "../../services/TimesheetService";
import moment from "moment-timezone";
import SelectCommon from "../../components/formComponents/selectcommon";
import { LabelValue } from "../../interface/general/general.interface";
import { useBreadCrumbs } from "../../context/breadcrumb.context";
import { ApprovalModal } from "../../components/modal/Approve.Modal";
import { months, years } from "../../constants/TimeSheetConstants";
import Button from "../../components/formComponents/Button";
import {
  DateRecordsMap,
  TimesheetAnalytics,
  TimesheetUser,
} from "../../interface/business/viewTimesheet.interface";

const now = moment().tz(moment.tz.guess());
// Extract the month and year with the desired format
const currentMonth = now.format("MM"); // Two-digit month with leading zero (e.g., 01, 02)
const currentYear = now.format("YYYY"); // Four-digit year (e.g., 2023)
const currentMonthMMMM = now.format("MMMM");

function ViewTimesheet() {
  const { userId, jobId } = useParams<{ userId: string; jobId: string }>();
  const { setBreadCrumbs } = useBreadCrumbs();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<TimesheetUser>({
    name: "",
    email: "",
  });

  const [analytics, setAnalytics] = useState<TimesheetAnalytics>({
    total_working_days: 0,
    total_working_hours: 0,
  });

  const [timesheet, setTimesheet] = useState<DateRecordsMap>({});

  const [employeeIdToApprove, setEmployeeIdToApprove] = useState<number | null>(
    null
  );

  const [isApproving, setIsApproving] = useState(false);

  const [month, setMonth] = useState<LabelValue>();
  const [year, setYear] = useState<LabelValue>();

  const location = useLocation();

  const { state }: { state: { businessId: number } } = location;

  const businessId: number = state?.businessId;

  const handleDeleteConfirm = async (): Promise<void> => {
    if (!employeeIdToApprove) {
      return;
    }
    setIsApproving(true);

    const payload = {
      userId: Number(userId),
      jobId: Number(jobId),
      month: `${year?.value}-${month?.value}`,
    };

    try {
      await ApproveTimesheet(payload);
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setEmployeeIdToApprove(null);
      setIsApproving(false);
    }
  };

  function getCurrentUserMonth() {
    setYear({
      label: currentYear,
      value: currentYear,
    });

    setMonth({
      label: currentMonthMMMM,
      value: `${currentMonth}`,
    });
  }

  const getUser = async () => {
    const res = await ViewUserById(Number(userId));
    setUser(res.data?.responseData);
  };

  const getUserAnalytics = async () => {
    setLoading(true);
    const payload = {
      userId: userId,
    };
    const res = await GetEmployeeanalytics(payload);
    setAnalytics(res.data?.responseData);
    setLoading(false);
  };

  const getUserTimesheet = async () => {
    setLoading(true);
    if (month?.value && year?.value) {
      const payload = {
        userId: userId,
        jobId: jobId,
        month: `${year.value}-${month.value}`,
      };
      const res = await GetEmployeeTimesheet(payload);
      setTimesheet(res.data?.responseData);
    }
    setLoading(false);
  };

  useEffect(() => {
    setBreadCrumbs([
      { link: "/business", name: "Business" },
      { link: `/list-employees/${businessId}`, name: "Employee Attendance" },
      { link: `#`, name: "Timesheet Details" },
    ]);

    return () => {
      setBreadCrumbs([]);
    };
  }, []);

  useEffect(() => {
    getCurrentUserMonth();
    getUser();
    getUserAnalytics();
  }, []);

  useEffect(() => {
    setLoading(true);
    getUserTimesheet();
  }, [month, year]);

  return (
    <section className=" w-full pt-10 px-4 sm:px-6 md:px-8 flex self-stretch  ">
      <div className="shadow-md mb-2 bg-white p-5 rounded-lg w-full flex flex-col  min-h-[calc(100vh-150px)]  ">
        <div className="top-attendance-history flex flex-wrap lg:flex-nowrap justify-between gap-10 mb-10">
          <div className="">
            <div className="flex flex-wrap sm:flex-nowrap gap-5">
              <img
                src={profile}
                width={50}
                height={50}
                className="w-[50px] h-[50px] object-cover rounded-full"
                alt=""
              />
              <div>
                <h1 className="text-2xl font-semibold line-clamp-1">
                  {user.name}
                </h1>
                <p className="flex gap-3 items-center line-clamp-1">
                  {user.email}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 items-center">
            <div className="p-5 border border-navLinkHover shadow-sm rounded-md ">
              <p className="text-primary font-medium">Total Attendance </p>
              <h3 className="text-lg font-semibold">
                {analytics.total_working_days} Days
              </h3>
            </div>
            <div className="p-5 border border-navLinkHover shadow-sm  rounded-md">
              <p className="text-primary font-medium">Total Hours </p>
              <h3 className="text-lg font-semibold">
                {Number(analytics.total_working_hours).toFixed(2)} Hours
              </h3>
            </div>
            <Button
              type="button"
              className="p-4 rounded-md border border-transparent bg-primary text-white hover:border-primary hover:bg-white hover:text-primary transition-all shadow-sm text-lg font-semibold"
              onClick={() => {
                setEmployeeIdToApprove(Number(userId));
              }}
            >
              <>
                Approve
                <div>Timesheet</div>
              </>
            </Button>
          </div>
        </div>

        <hr className="mb-5" />

        <div className="main-cards-attendance">
          <div className="filter-div mb-10 flex flex-wrap md:flex-nowrap justify-between gap-5">
            <div className="">
              <h2 className="text-lg font-semibold line-clamp-1">
                Attendance History
              </h2>
              <p className="line-clamp-1">{currentMonthMMMM}</p>
            </div>
            <div className="flex flex-row justify-start md:justify-end pb-2 max-w-[400px] gap-5 w-full ">
              <SelectCommon
                isMultipleSelect={false}
                value={
                  years?.find(
                    (item: LabelValue) => item.value === year?.value
                  ) ?? null
                }
                options={years}
                onSelect={(valueObj: LabelValue) => {
                  setYear(valueObj);
                }}
              />
              <SelectCommon
                isMultipleSelect={false}
                value={
                  months?.find(
                    (item: LabelValue) => item.value === month?.value
                  ) ?? null
                }
                options={months}
                onSelect={(valueObj: LabelValue) => {
                  setMonth(valueObj);
                }}
              />
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center bg-white mt-4">
              <span className="circle animate-loader"></span>
              <span className="circle animate-loader animation-delay-200"></span>
              <span className="circle animate-loader animation-delay-400"></span>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 ">
              {timesheet !== null &&
                Object.keys(timesheet).map((time) => {
                  return (
                    <CardAttendance
                      time={time}
                      checkIo={timesheet[time]}
                      key={JSON.stringify(timesheet[time].slice(0, 6))}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <ApprovalModal
        isOpen={!!employeeIdToApprove}
        onClose={() => setEmployeeIdToApprove(null)}
        onConfirm={handleDeleteConfirm}
        isLoading={isApproving}
        message={`Are you sure you want to approve timesheet for employee ${user.name}`}
      />
    </section>
  );
}

export default ViewTimesheet;
