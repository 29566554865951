import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeToken,
  setToken,
  setUser,
  userSelector,
} from "../../redux/slices/authSlice";
import BreadCrumbs from "../breadCrumbs/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import notification from "../../assets/images/notification.svg";
import Button from "../formComponents/Button";
import BlankImageSvg from "../../assets/images/avatar-boy.svg";
import { socketSelector } from "../../redux/slices/socketSlice";
import NotificationSubMenu from "../notificationComponent/NotificationSubMenu";
import { GetAllNotification } from "../../services/NotificationService";
import { ApiResponse } from "../../interface/notification/notificationInterfce";

const DashboardHeader: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(userSelector);
  const socket = useSelector(socketSelector);
  const [isProfileDropDownOpen, setIsProfileDropDownOpen] = useState(false);
  const profileDropdownRef = useRef<HTMLDivElement>(null);

  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationSubMenu, setShowNotificationSubMenu] = useState(false);

  const [unreadNotificationData, setUnreadNotificationData] =
    useState<ApiResponse | null>(null);

  const handleLogOut = () => {
    dispatch(removeToken());
    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate("/login");
  };

  const unReadNotificationData = async () => {
    const response = await GetAllNotification("?read=false");
    const { response_type } = response.data;
    if (response_type === "success") {
      setUnreadNotificationData(response.data.responseData);
    }
  };

  const handleDisplayNotificationSubMenu = async () => {
    setShowNotificationSubMenu(!showNotificationSubMenu);
  };

  useEffect(() => {
    socket?.on("count", (e) => {
      setNotificationCount(e);
    });
    unReadNotificationData();
  }, [socket, notificationCount]);

  // For Hide and Show Profile Dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsProfileDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header className="sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full bg-white border-b text-sm py-2.5 sm:py-4   ">
      <nav className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8">
        <div className="mr-5 lg:mr-0  ">
          <BreadCrumbs />
        </div>

        <div className="w-full flex items-center justify-end ml-auto sm:justify-between sm:gap-x-3 sm:order-3">
          <div className="flex flex-row items-center justify-end gap-2 ml-auto">
            <div className="hs-dropdown relative inline-flex group ">
              <div
                className="relative"
                onClick={() => handleDisplayNotificationSubMenu()}
              >
                <Button
                  type="button"
                  className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium  text-gray-700 align-middle  bg-navLinkHover focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs mr-3  "
                >
                  <img src={notification} alt="notification" />
                </Button>
                {notificationCount > 0 && (
                  <div className="absolute bg-primary text-white w-5 h-5 flex justify-center items-center top-[-5px] right-0 text-[10px] rounded-full">
                    <span className="">{notificationCount}</span>
                  </div>
                )}
              </div>

              {showNotificationSubMenu && unreadNotificationData && (
                <NotificationSubMenu
                  unreadNotificationData={unreadNotificationData}
                  setShowNotificationSubMenu={setShowNotificationSubMenu}
                  onNotificationRemove={() => {
                    setNotificationCount(notificationCount - 1);
                  }}
                />
              )}
            </div>

            <div
              className="relative inline-flex group"
              ref={profileDropdownRef}
              onClick={() => {
                setIsProfileDropDownOpen(!isProfileDropDownOpen);
              }}
            >
              <Button
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium hover:bg-black/25 text-gray-700 align-middle bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs mb-1"
              >
                <img
                  className="inline-block h-[2.375rem] w-[2.375rem] rounded-full ring-2 ring-white "
                  src={
                    userData?.profileImage
                      ? `${userData.profileImage}`
                      : `${BlankImageSvg}`
                  }
                  alt=""
                />
              </Button>

              <div className="pl-3 pr-5">
                <div className="text-textColor font-medium">
                  {userData?.name}
                </div>
                <div className="text-textColor text-xs ">{userData?.name}</div>
              </div>

              {isProfileDropDownOpen && (
                <div className=" fixed right-[30px] top-[58px] duration  min-w-[12rem] bg-white shadow-md rounded-lg p-2 ">
                  <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg ">
                    <p className="text-sm text-gray-500 ">EMAIL</p>
                    <p className="text-sm font-medium text-gray-800 ">
                      {userData?.email}
                    </p>
                  </div>
                  <div
                    className="mt-2 py-2 first:pt-0 last:pb-0"
                    onClick={handleLogOut}
                  >
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-white focus:ring-2 focus:ring-blue-500 "
                      to="/login"
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default DashboardHeader;
