import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ApiResponse } from "../../interface/notification/notificationInterfce";
import userProfile from "../../assets/images/user-profile.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import noDataImg from "../../assets/images/no-data-found.svg";
import { UpdateNotification } from "../../services/NotificationService";

interface NotificationSubMenuProps {
  unreadNotificationData: ApiResponse;
  setShowNotificationSubMenu: Dispatch<SetStateAction<boolean>>;
  onNotificationRemove: () => void;
}

const NotificationSubMenu: React.FC<NotificationSubMenuProps> = ({
  unreadNotificationData,
  setShowNotificationSubMenu,
  onNotificationRemove,
}) => {
  const navigate = useNavigate();
  const notificationRef = useRef<HTMLDivElement>(null);

  const [unReadNotificationDataState, setUnReadNotificationDataState] =
    useState<ApiResponse>(unreadNotificationData);

  const handleShowAllNotification = () => {
    navigate("/notification");
    setShowNotificationSubMenu(false);
  };

  const handleNotificationClickOutside = (event: MouseEvent) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setShowNotificationSubMenu(false);
    }
  };

  const handleNotificationRead = async (id: number) => {
    const response = await UpdateNotification(id);
    const { response_type } = response.data;
    if (response_type === "success") {
      const updatedNotifications = unReadNotificationDataState.rows.filter(
        (notification) => notification.id !== id
      );
      const updatedUnreadCount = unReadNotificationDataState.count - 1;
      setUnReadNotificationDataState({
        ...unReadNotificationDataState,
        rows: updatedNotifications,
        count: updatedUnreadCount,
      });
      onNotificationRemove();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleNotificationClickOutside, true);
    return () => {
      document.removeEventListener(
        "click",
        handleNotificationClickOutside,
        true
      );
    };
  }, []);

  return (
    <div
      className="fixed right-[10px] sm:right-[165px] top-[56px]  duration bg-white shadow-md rounded-lg max-w-[280px] w-full"
      ref={notificationRef}
    >
      <div className="">
        <div className="py-3 px-5 bg-gray-100 rounded-t-lg flex items-center justify-between w-full font-bold">
          Notifications{" "}
          <span className="bg-primary text-white text-[10px] font-medium py-1 px-2 rounded-full">
            {unReadNotificationDataState.count} Unread
          </span>
        </div>
        <div className="min-h-[308px] max-h-[308px] h-full bg-white overflow-auto scroll-design">
          {unReadNotificationDataState.rows.length > 0 ? (
            unReadNotificationDataState.rows.map((notification, index) => {
              return (
                <div
                  key={notification.id}
                  className="flex gap-3 p-2 border-b border-slate-300 cursor-pointer"
                  onClick={() =>
                    handleNotificationRead(
                      unReadNotificationDataState.rows[index].id
                    )
                  }
                >
                  <div className="min-w-[32px] h-[32px]  text-base rounded-full flex items-center justify-center">
                    <img
                      className="h-[32px] w-[32px] object-cover rounded-full"
                      src={
                        notification?.addedByUserSenderId?.profileImage
                          ? `${notification?.addedByUserSenderId?.profileImage}`
                          : userProfile
                      }
                      alt="profileImage"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">
                      {notification.addedByUserSenderId?.name}
                    </p>
                    {notification.message}
                    <p className="text-[11px]">
                      {moment(notification.createdAt).format("DD MMM YYYY h A")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-design min-h-[308px] h-full flex flex-col justify-center  text-center py-7">
              <img src={noDataImg} alt="" className="w-20 h-20 mx-auto" />
              <p className="text-md py-5 font-semibold text-primary">
                Nothing to show
              </p>
            </div>
          )}
        </div>
        <div className="rounded-b-lg text-center bg-primary hover:brightness-125 text-white font-medium cursor-pointer">
          <p className="block p-3 " onClick={() => handleShowAllNotification()}>
            View all Notifications
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationSubMenu;
