import React from "react";
import { setActiveTab } from "../../redux/slices/adminSidebarSlice";
import { useDispatch } from "react-redux";
import { IMenuList } from "../../interface/leftSideBar/leftSideBar";
import { ArrowDownIcon } from "../../utils/svgIcons";

const LinkContent = ({
  e,
  adminSidebar,
  activeTab,
  toggle,
  setToggle,
}: {
  e: IMenuList;
  adminSidebar: boolean;
  activeTab: string;
  toggle: string | null;
  setToggle: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const dispatch = useDispatch();
  const isActive =
    activeTab === e.link ||
    (e.subMenu &&
      e.subMenuList?.some((sub: { link: string }) => sub.link === activeTab));

  const isSubMenuOpen = toggle === e.name;

  const toggleSubMenu = () => {
    if (toggle !== e.name && e.subMenu) {
      setToggle(e.name);
    } else {
      setToggle(null);
    }
    if (!e.subMenu) {
      dispatch(setActiveTab(e.link));
    } else if (e.subMenuList && e.subMenuList.length > 0) {
      dispatch(setActiveTab(e.subMenuList[0].link));
    }
  };

  return (
    <>
      {e.icon && (
        <span
          className={`icon block ${
            isActive ? "stroke-0 " : "stroke-secondary "
          }
            ${adminSidebar ? "mr-2" : ""}
          `}
        >
          {<e.icon />}
        </span>
      )}
      {adminSidebar ? (
        <>
          <span
            className={`text-sm font-base transition-all delay-300 ${
              isActive ? "text-primary" : "text-textColor"
            }`}
          >
            {e.name}
          </span>
          {e.subMenu && (
            <span
              className={`down-icon flex ml-auto text-grayDark w-6 h-6 justify-center rounded items-center hover:bg-grayDark/10`}
              onClick={toggleSubMenu}
            >
              <ArrowDownIcon
                className={`${isSubMenuOpen ? "" : "-rotate-90"}`}
              />
            </span>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LinkContent;
