import React, { ChangeEvent } from "react";
interface IInput {
  type: string;
  value?: string | number;
  name?: string;
  id?: string;
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoComplete?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  accept?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

const Input: React.FC<IInput> = ({
  onKeyDown,
  type,
  value,
  id,
  className,
  onChange,
  placeholder,
  name,
  autoComplete,
  disabled,
  accept,
  onFocus,
}) => {
  return (
    <input
      type={type}
      value={value}
      name={name}
      id={id}
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={autoComplete}
      onKeyDown={onKeyDown}
      disabled={disabled}
      accept={accept}
      onFocus={onFocus}
    />
  );
};

export default Input;
