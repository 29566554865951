import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserData } from "../../interface/user/userInterface";

export interface IuserInitialRedux {
    token: null | string;
    user: IUserData | null;
}

const initialState = {
    token: null,
    user: null,
};
export const userSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (
            state: IuserInitialRedux,
            action: PayloadAction<IUserData | null>
        ) => {
            state.user = action.payload;
        },
        setToken: (
            state: IuserInitialRedux,
            action: PayloadAction<string | null>
        ) => {
            state.token = action.payload;
        },
        removeToken: (state: IuserInitialRedux) => {
            state.token = null;
        },
    },
});


export const userSelector = (state: { auth: IuserInitialRedux }) =>
    state.auth.user;

export const tokenSelector = (state: { auth: IuserInitialRedux }) =>
    state.auth.token;

const { actions, reducer } = userSlice;

export const { setUser, setToken, removeToken } = actions;

export const setuser = (data: IUserData | null) => {
    setUser(data);
};
export const settoken = (data: string) => {
    setToken(data);
};

export default reducer;

