import { FC } from "react";
import moment from "moment-timezone";

type CheckinRecord = {
  id: number;
  checkin: string; // Format: "YYYY-MM-DD HH:mm:ss"
  checkout: string; // Format: "YYYY-MM-DD HH:mm:ss"
  hours_worked: number;
  is_edited: number;
  is_approved: number;
};
interface TimesheetCardProps {
  time: string;
  checkIo: CheckinRecord[];
}

function convertTo12HourFormat(time24: string) {
  // Split the input time into hours and minutes
  const [hours, minutes] = time24.split(":");

  // Convert the hours to 12-hour format
  let hours12 = parseInt(hours, 10) % 12;
  hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (00:00) as 12 AM

  // Determine whether it's AM or PM
  const period = parseInt(hours, 10) < 12 ? "AM" : "PM";

  // Format the result
  const time12 = `${String(hours12).padStart(2, "0")}:${minutes} ${period}`;

  return time12;
}

const CardAttendance: FC<TimesheetCardProps> = ({ time, checkIo }) => {
  const date = moment(time).format("MMM D, YYYY");
  const dayOfWeekInTimezone = moment(time).format("dddd");
  return (
    <div className="border-l-2 border-primary card-main-day border-t border-t-navLinkHover border-b border-b-navLinkHover border-r border-r-navLinkHover  p-5">
      <p className="pb-3 mb-3 border-b  border-navLinkHover ">
        {dayOfWeekInTimezone} {","}
        {date}
      </p>
      <div className="overflow-x-auto">
        <table className=" w-full ">
          <thead className="w-full">
            <tr>
              <th className="w-[40%] text-left font-normal">
                <small>Check In</small>
              </th>
              <th className="w-[40%] text-left font-normal">
                <small>Check Out</small>
              </th>
              <th className="w-[20%] text-left font-normal">
                <small>Hours</small>
              </th>
            </tr>
          </thead>

          <tbody className="w-full">
            {checkIo.map((check: CheckinRecord) => (
              <tr key={check.id}>
                <td>
                  <h4 className="text-md font-medium">
                    {convertTo12HourFormat(
                      moment(check.checkin).format("HH:mm")
                    )}
                  </h4>
                </td>
                <td>
                  <h4 className="text-md font-medium">
                    {check.checkout === ""
                      ? ""
                      : convertTo12HourFormat(
                          moment(check.checkout).format("HH:mm")
                        )}
                  </h4>
                </td>
                <td>
                  <h4 className="text-md font-medium">
                    {Number(check.hours_worked).toPrecision(2)}
                  </h4>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardAttendance;
