export const months = [
  {
    value: `01`,
    label: "January",
  },
  {
    value: `02`,
    label: "February",
  },
  {
    value: `03`,
    label: "March",
  },
  {
    value: `04`,
    label: "April",
  },
  {
    value: `05`,
    label: "May",
  },
  {
    value: `06`,
    label: "June",
  },
  {
    value: `07`,
    label: "July",
  },
  {
    value: `08`,
    label: "August",
  },
  {
    value: `09`,
    label: "September",
  },
  {
    value: `10`,
    label: "October",
  },
  {
    value: `11`,
    label: "November",
  },
  {
    value: `12`,
    label: "December",
  },
];

export const years = [
  {
    value: `2021`,
    label: "2021",
  },
  {
    value: `2022`,
    label: "2022",
  },

  {
    value: `2023`,
    label: "2023",
  },
  {
    value: `2024`,
    label: "2024",
  },
  {
    value: `2025`,
    label: "2025",
  },
  {
    value: `2026`,
    label: "2026",
  },
  {
    value: `2027`,
    label: "2027",
  },
  {
    value: `2028`,
    label: "2028",
  },
  {
    value: `2029`,
    label: "2029",
  },
  {
    value: `2030`,
    label: "2030",
  },
];
