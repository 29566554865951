import Select from "react-select";
import {
  LabelValue,
  CommonArraySelect,
} from "../../interface/general/general.interface";

interface DropdownProps {
  options: LabelValue[] | CommonArraySelect[];
  onSelect: (selectedOption: any) => void;
  value: any;
  placeholder?: string;
  isMultipleSelect: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
}

const SelectCommon = ({
  options,
  onSelect,
  value,
  placeholder,
  isMultipleSelect,
  isClearable = false,
  isSearchable = true,
}: DropdownProps) => {
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: "14px",
      fontWeight: "normal",
      borderRadius: "8px",
      padding: "2px 3px",
      border: "1px solid #E6E6E6 !important",
      boxShadow: "none",
      color: "",
      backgroundColor: "white",
      "&:focus": {
        border: "0 !important",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      backgroundColor: "#fff",
      borderRadius: "10px",
      fontSize: "14px",
      fontWeight: "normal",
      maxHeight: "160px",
    }),
    option: (base: any) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#546779",
      ":hover": {
        backgroundColor: "#2A9D8F",
        color: "#fff",
      },
    }),
  };

  const handleChange = (selectedOption: any) => {
    if (!selectedOption) {
      selectedOption = {
        value: "",
      };
    }
    onSelect(selectedOption);
  };

  return (
    <Select
      isSearchable={isSearchable}
      value={value}
      options={options}
      styles={selectStyles}
      onChange={handleChange}
      placeholder={placeholder}
      menuPlacement="auto"
      isMulti={isMultipleSelect}
      isClearable={isClearable}
    />
  );
};

export default SelectCommon;
