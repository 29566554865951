import {
  CMSIcon,
  ChatIcon,
  SidebarHome,
  User,
  BusinessIcon,
  Post,
  AgreementIcon,
  PaySlip,
} from "../utils/svgIcons";

export const MenuList = [
  {
    id: 1,
    name: "Dashboard",
    link: "/",
    icon: SidebarHome,
    subMenu: false,
  },
  {
    id: 2,
    name: "Employees",
    link: "/user",
    icon: User,
    subMenu: false,
  },
  {
    id: 3,
    name: "CMS",
    link: "#!",
    icon: CMSIcon,
    subMenu: true,
    subMenuList: [
      {
        id: "term&condition",
        name: "Terms & Condition",
        link: "/terms&condition",
      },
      {
        id: "privatePolicy",
        name: "Privacy Policies",
        link: "/privacy-policies",
      },
      {
        id: "welcome",
        name: "Welcome Page",
        link: "/welcome",
      },
      {
        id: "profileSetup",
        name: "Profile Setup",
        link: "/profile-setup",
      },
    ],
  },
  {
    id: 3,
    name: "Chat",
    icon: ChatIcon,
    link: "/chats",
    subMenu: false,
  },
  {
    id: 4,
    name: "Business",
    icon: BusinessIcon,
    link: "/business",
    subMenu: false,
  },
  {
    id: 5,
    name: "Post",
    icon: Post,
    link: "/post",
    subMenu: false,
  },
  {
    id: 6,
    name: "Agreements",
    icon: AgreementIcon,
    link: "/user-agreements",
    subMenu: false,
  },
  {
    id: 7,
    name: "Payslip",
    icon: PaySlip,
    link: "/payslip",
    subMenu: false,
  },
];
