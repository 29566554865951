import { axiosPost, axiosPut } from "../axios/axios";
const prefix = "/attendance";

interface EmpList {
  businessId: string | undefined;
}

interface User {
  userId: string | number | undefined;
}

interface Approval {
  userId: number | string;
  jobId: number | string;
  month: number | string;
}

export const GetAllEmployeesUnderBusiness = (query: string, data: EmpList) => {
  return axiosPost(`${prefix}/get-list-business-employee${query}`, data);
};

export const GetEmployeeanalytics = (data: User) => {
  return axiosPost(`${prefix}/get-admin-attendance-analytics`, data);
};

export const GetEmployeeTimesheet = (data: User) => {
  return axiosPost(`${prefix}/admin-view-emp-logs`, data);
};

export const ApproveTimesheet = (data: Approval) => {
  return axiosPut(`${prefix}/approveTimesheet`, data);
};
