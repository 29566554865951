import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../axios/axios";

const prefix = "/users";
const jobPrefix = "/job";

export const GetAllUser = (query: string) => {
  return axiosGet(`${prefix}${query}`);
};

export const DeleteUserById = (id: number) => {
  return axiosDelete(`${prefix}/${id}`);
};

export const ViewUserById = (id: number) => {
  return axiosGet(`${prefix}/${id}`);
};

export const EditUserData = (id: number, data: object, query?: string) => {
  return axiosPut(`${prefix}/user-profile/${id}${query ?? ""}`, data);
};

export const AddNewUser = (data: object) => {
  return axiosPost(`${prefix}/create-user`, data);
};

export const ChangeUserStatus = (id: number, data: object) => {
  return axiosPut(`${prefix}/change-user-status/${id}`, data);
};

export const getAllCompletedJobs = (id: number) => {
  return axiosGet(`${jobPrefix}/get-completed-jobs/${id}`);
};
