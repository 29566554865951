import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import toastReducer from "./slices/toastSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AdminSideBarReducer from "./slices/adminSidebarSlice";
import socketReducer from "./slices/socketSlice";

const persistConfig = {
    key: "PADDLE",
    storage,
    whitelist: ["auth", "AdminSideBar"],
};

const rootReducer = combineReducers({
    auth: authReducer,
    toast: toastReducer,
    AdminSideBar: AdminSideBarReducer,
    socket: socketReducer,

});

export default persistReducer(persistConfig, rootReducer);
