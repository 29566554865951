import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

interface IBreadCrumb {
  link: string;
  name: string;
}

interface IBreadCrumbsContext {
  breadCrumbs: IBreadCrumb[];
  setBreadCrumbs: Dispatch<SetStateAction<IBreadCrumb[]>>;
}
interface IBreadCrumbsProvider {
  children: ReactNode;
}

const BreadCrumbsContext = createContext<IBreadCrumbsContext | null>(null);

export const BreadCrumbsProvider: FC<IBreadCrumbsProvider> = ({ children }) => {
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumb[]>([]);

  const values = useMemo(() => ({ breadCrumbs, setBreadCrumbs }), [
    breadCrumbs,
    setBreadCrumbs,
  ]);

  return (
    <BreadCrumbsContext.Provider value={values}>
      {children}
    </BreadCrumbsContext.Provider>
  );
};

export const useBreadCrumbs = () => {
  const context = useContext(BreadCrumbsContext);

  if (context === null) {
    throw new Error(
      "useBreadCrumbs hook must be used within BreadCrumbsProvider component."
    );
  }

  return context;
};
