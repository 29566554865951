import { useState } from "react";
import { ITableProps } from "../../interface/table/table.interface";
import Pagination from "../pagination";
import noDataImg from "../../assets/images/no-data-found.svg";

const ReactTable = (props: ITableProps) => {
  const [filterToggle, setFilterToggle] = useState<boolean>(false);

  const sortTableData = (field: string) => {
    const regex = /^[0-9.%]+$/;
    if (filterToggle) {
      props.bodyData?.sort(
        (a: { [key: string]: string }, b: { [key: string]: string }) => {
          let x: string | number = a[field];
          let y: string | number = b[field];

          if (regex.test(x) && regex.test(y)) {
            x = parseInt(a[field]);
            y = parseInt(b[field]);
          } else {
            x = a[field].toLowerCase();
            y = b[field].toLowerCase();
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }
      );
    } else {
      props.bodyData?.sort(
        (b: { [key: string]: string }, a: { [key: string]: string }) => {
          let x: string | number = a[field];
          let y: string | number = b[field];

          if (regex.test(x) && regex.test(y)) {
            x = parseInt(a[field]);
            y = parseInt(b[field]);
          } else {
            x = a[field].toLowerCase();
            y = b[field].toLowerCase();
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }
      );
    }
  };

  const headerRowClass = "custom-header-row   text-base text-black";

  const headerCellStyle: React.CSSProperties = {
    backgroundColor: "#2a9d8f30",
    fontWeight: "500",
    padding: "17px",
    border: "0px solid #ccc",
  };
  const bodyRowClass = "custom-body-row ";
  const bodyCellStyle: React.CSSProperties = {
    padding: "12px",
    border: "1px solid #F5F5F5",
    textAlign: "left",
    color: "#546779",
    fontWeight: "normal",
    backgroundColor: "#fff",
    fontSize: "14px",
  };

  return (
    <div>
      {props?.isLoading === true ? (
        <table className=" usertable w-full ">
          <thead className="table-border">
            <tr className={headerRowClass}>
              {props.headerData.map(
                (val: { [key: string]: string | object }, index: number) => {
                  return (
                    <th
                      className={`text-left last:text-right ${
                        val.headerClassName ? val.headerClassName : ""
                      }`}
                      key={index}
                      style={headerCellStyle}
                    >
                      <p className="header_text capitalize">
                        <>{val.header}</>
                      </p>
                      {(val?.option as { sort?: string })?.sort ? (
                        <h2
                          className="up_arrow"
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setFilterToggle(!filterToggle);
                            typeof val.name == "string" &&
                              sortTableData(val.name);
                            (e.target as Element).classList.toggle(
                              "transform_arrow"
                            );
                          }}
                        >
                          &#8593;
                        </h2>
                      ) : (
                        ""
                      )}
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody className="tablebody relative">
            <tr className="pt-28 pb-20 block">
              <td
                colSpan={5}
                className="flex justify-center items-center absolute inset-0 bg-white mt-4 "
              >
                <span className="circle animate-loader"></span>
                <span className="circle animate-loader animation-delay-200"></span>
                <span className="circle animate-loader animation-delay-400"></span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="usertable w-full ">
          <thead className="table-border">
            <tr className={headerRowClass}>
              {props.headerData.map(
                (val: { [key: string]: string | object }, index: number) => {
                  return (
                    <th
                      className={`text-left last:text-right ${
                        val.headerClassName ? val.headerClassName : ""
                      }`}
                      key={index}
                      style={headerCellStyle}
                    >
                      <p className="header_text capitalize">
                        <>{val.header}</>
                      </p>
                      {(val?.option as { sort?: string })?.sort ? (
                        <h2
                          className="up_arrow"
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setFilterToggle(!filterToggle);
                            typeof val.name == "string" &&
                              sortTableData(val.name);
                            (e.target as Element).classList.toggle(
                              "transform_arrow"
                            );
                          }}
                        >
                          &#8593;
                        </h2>
                      ) : (
                        ""
                      )}
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody className="tablebody">
            {props.bodyData && props.bodyData.length > 0 ? (
              props.bodyData.map(
                (row: { [key: string]: string }, rowIndex: number) => {
                  return (
                    <tr key={`row-${rowIndex}`} className={bodyRowClass}>
                      {props.headerData.map(
                        (
                          columnCell: { [key: string]: string | any },
                          colIndex: number
                        ) => {
                          return (
                            <td
                              className={columnCell.bodyClassName}
                              key={colIndex}
                              style={
                                columnCell.bodyStyle
                                  ? {
                                      ...bodyCellStyle,
                                      ...columnCell.bodyStyle,
                                    }
                                  : bodyCellStyle
                              }
                              onClick={() =>
                                // event: React.MouseEvent<HTMLElement>
                                columnCell.onclickEvent
                                  ? (
                                      columnCell as object as {
                                        [key: string]: (
                                          arg: number | string
                                        ) => void;
                                      }
                                    ).onclickEvent(row.id)
                                  : null
                              }
                            >
                              {columnCell.cell ? (
                                (
                                  columnCell as object as {
                                    [key: string]: (
                                      arg: number | string | object
                                    ) => keyof typeof row;
                                  }
                                ).cell(row)
                              ) : (
                                <p>
                                  {row[columnCell.name]
                                    ? row[columnCell.name].length > 25
                                      ? row[columnCell.name].substr(0, 25) +
                                        "..."
                                      : row[columnCell.name]
                                    : "-"}
                                </p>
                              )}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="no-data-design text-center py-7">
                    <img src={noDataImg} alt="" className="w-36 h-36 mx-auto" />
                    <p className="text-lg py-5 font-semibold text-primary">
                      Nothing to show
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {props.bodyData && props.bodyData.length > 0 && (
            <tfoot>
              <tr>
                <td colSpan={2}>
                  Showing{" "}
                  <span className="font-semibold">
                    &nbsp; {Math.min(props.page * props.limit, props.dataCount)}{" "}
                    &nbsp;
                  </span>
                  of {props.dataCount} entries
                </td>

                <td colSpan={5}>
                  <Pagination
                    total={props.dataCount}
                    limit={props.limit}
                    page={props.page}
                    onHandlePage={props.onHandlePage}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      )}
    </div>
  );
};
export default ReactTable;
