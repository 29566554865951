import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useBreadCrumbs } from "../../context/breadcrumb.context";
import { GetAllEmployeesUnderBusiness } from "../../services/TimesheetService";
import { useParams, useNavigate } from "react-router-dom";
import time from "../../assets/images/time-svg.svg";
import { ITableColumns } from "../../interface/table/table.interface";
import Input from "../../components/formComponents/Input";
import ReactTable from "../../components/react-table/table";
import search from "../../assets/images/search.svg";
import { useDebounce } from "../../hooks/useDebounce";
import moment_tz from "moment-timezone";
import Button from "../../components/formComponents/Button";

const ListEmployees: FC = () => {
  const { setBreadCrumbs } = useBreadCrumbs();
  const navigate = useNavigate();

  interface JobUser {
    userId: number;
    jobPostId: number;
    status: string;
    jobParticularUser: {
      id: number;
      name: string;
      email: string;
      roleId: number;
      status: string;
      phone: string;
      gender: null | string;
    };
    job_post_data: {
      id: number;
      jobTitle: string;
      jobType: string;
      salary: number;
      createdAt: string;
    };
  }

  const [employeeData, setEmployeeData] = useState<{
    data: JobUser[];
    totalPage: number;
    totalCount: number;
  }>({
    data: [],
    totalPage: 0,
    totalCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isFetchingEmployee, setIsFetchingEmployee] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const limit = 10;

  const handleSearchInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchInput(e.target.value);
  };

  const debouncedValue = useDebounce(searchInput);

  async function fetchAllEmployees(pageNumber = 1, limit = 10, query?: string) {
    const queryString = `?page=${pageNumber}&limit=${limit}&search=${query}`;
    const body = {
      businessId: id,
    };
    try {
      setIsFetchingEmployee(true);
      const response = await GetAllEmployeesUnderBusiness(queryString, body);
      if (response?.data?.responseData) {
        const result = response?.data?.responseData;
        setEmployeeData({
          data: result.data,
          totalCount: result.count,
          totalPage: result.lastPage,
        });
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsFetchingEmployee(false);
    }
  }

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchAllEmployees(newPage, limit, searchInput);
  };

  useEffect(() => {
    setBreadCrumbs([
      { link: "/business", name: "Business" },
      { link: "#", name: "Employee Attendance" },
    ]);

    return () => {
      setBreadCrumbs([]);
    };
  }, []);

  useEffect(() => {
    fetchAllEmployees(currentPage, limit, debouncedValue);
  }, [debouncedValue, currentPage]);

  const emailColStyle: React.CSSProperties = {
    borderTop: "1px solid #F5F5F5",
    borderBottom: "1px solid #F5F5F5",
    tableLayout: "fixed",
    width: "20%",
  };

  const nameColumn = (props: JobUser) => {
    return <div className="text-left">{props.jobParticularUser.name}</div>;
  };

  const emailCloumn = (props: JobUser) => {
    return <div className="text-left">{props.jobParticularUser.email}</div>;
  };

  const jobTitleColumn = (props: JobUser) => {
    return <div className="text-left">{props.job_post_data.jobTitle}</div>;
  };

  const statusColumn = (props: JobUser) => {
    return (
      <div className="text-left">
        <span className="bg-yellow-200 border border-yellow-400 py-1 px-2 text-yellow-700 rounded-full">
          {props.status}
        </span>
      </div>
    );
  };

  const postedOnCloumn = (props: JobUser) => {
    return (
      <div className="text-left">
        {moment_tz
          .utc(props.job_post_data.createdAt)
          .tz(moment_tz.tz.guess())
          .format("YYYY-MM-DD")}
      </div>
    );
  };

  const actionButton = (props: JobUser) => {
    return (
      <div className=" relative flex justify-end">
        <div className="flex gap-3 justify-center min-w-[120px]">
          <Button
            type="button"
            className=" cursor-pointer flex items-center justify-center  hover:brightness-50"
            onClick={() => {
              navigate(`/view-timesheet/${props.userId}/${props.jobPostId}`, {
                state: { businessId: id },
              });
            }}
          >
            <img
              src={time}
              width={24}
              height={24}
              className="stroke-view"
              alt="view"
            />
          </Button>
        </div>
      </div>
    );
  };

  const columns: ITableColumns[] = useMemo(
    () => [
      {
        header: "Name",
        name: "name",
        cell: (props: JobUser) => {
          return nameColumn(props);
        },
        bodyStyle: emailColStyle,
      },
      {
        header: "Email",
        name: "email",
        cell: (props: JobUser) => {
          return emailCloumn(props);
        },
        bodyStyle: emailColStyle,
      },
      {
        header: "Job Title",
        name: "job_title",
        cell: (props: JobUser) => {
          return jobTitleColumn(props);
        },
        bodyStyle: emailColStyle,
      },
      {
        header: "Status",
        name: "status",
        cell: (props: JobUser) => {
          return statusColumn(props);
        },
        bodyStyle: emailColStyle,
      },
      {
        header: "Posted On",
        name: "posted_on",
        cell: (props: JobUser) => {
          return postedOnCloumn(props);
        },
        bodyStyle: emailColStyle,
      },
      {
        header: "Action",
        cell: (props: JobUser) => {
          return actionButton(props);
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="w-full pt-4 px-4 sm:px-6 md:px-8">
        <div className=" flex justify-between items-center py-3">
          <div className="relative  ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <img src={search} alt="Search" />
            </div>
            <Input
              type="text"
              value={searchInput}
              onChange={handleSearchInputValue}
              className="pl-10 bg-primary/5 bg-white border border-borderIcon text-navDefault text-base sm:text-sm rounded-md focus:ring-primary/10 focus:border-primary/25  block w-full p-2.5  focus:outline-none"
              placeholder="Search employees"
            />
          </div>
        </div>

        <div className="overflow-x-auto scroll-design-x min-h-[100px]">
          <ReactTable
            isLoading={isFetchingEmployee}
            tableHeading={"Header name"}
            headerData={columns}
            bodyData={employeeData.data}
            dataCount={employeeData.totalCount}
            limit={limit}
            page={currentPage}
            totalPages={employeeData.totalPage}
            setPage={setCurrentPage}
            search={false}
            currentDataLength={employeeData.data.length}
            searchText={searchInput}
            setSearchText={setSearchInput}
            onHandlePage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ListEmployees;
