import React, { FC } from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-white">
      <span className="circle animate-loader  "></span>
      <span className="circle animate-loader animation-delay-200"></span>
      <span className="circle animate-loader animation-delay-400"></span>
    </div>
  );
};

export default Loader;

interface SmallLoaderProps {
  className?: string,
  backgroundColorClass?: string;
}

export const SmallLoader: FC<SmallLoaderProps> = ({
  className = 'h-28',
  backgroundColorClass = ''
}) => {
  return (
    <div className={`relative ${className}`}>
      <div className={`flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 ${backgroundColorClass}`}>
        <span className="circle animate-loader  "></span>
        <span className="circle animate-loader animation-delay-200"></span>
        <span className="circle animate-loader animation-delay-400"></span>
      </div>
    </div>
  );
};



