import "./index.css";
import RouterComponent from "./router/RouterComponent";
import ToastNotification from "./components/toastNotification/ToastNotification";
import { Suspense } from "react";
import Loader from "./utils/Loader";
import SocketComponent from "./components/socketComponent/SocketComponent";
import { BreadCrumbsProvider } from "./context/breadcrumb.context";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <SocketComponent />
      <BreadCrumbsProvider>
        <RouterComponent />
      </BreadCrumbsProvider>
      <ToastNotification />
    </Suspense>
  );
}

export default App;
