import { tokenSelector, userSelector } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_API_URL } from "../../config";
import { setSocket } from "../../redux/slices/socketSlice";
import { useEffect } from "react";
import openSocket from "socket.io-client";
const SocketComponent = () => {
  const dispatch = useDispatch();

  const token = useSelector(tokenSelector);

  const user = useSelector(userSelector);

  const connectSocket = async () => {
    if (token !== null) {
      const url = REACT_APP_API_URL;

      const socket = openSocket(url, {
        forceNew: true,
        transports: ["websocket"],
        query: {
          token: token,
        },
      });
      dispatch(setSocket(socket));
    }
  };
  useEffect(() => {
    connectSocket();
  }, [user, token]);

  return <></>;
};
export default SocketComponent;
