import React from "react";
import { usePagination } from "../../hooks/usePagination";
import Button from "../formComponents/Button";

export interface PropsTypes {
  total: number;
  limit: number;
  page: number;
  onHandlePage: (value: number) => void;
}

const Pagination: React.FC<PropsTypes> = ({
  total,
  limit,
  page,
  onHandlePage,
}) => {
  const paginationRange = usePagination({
    totalCount: total ?? 1,
    pageSize: limit,
    siblingCount: 1,
    currentPage: page,
  });

  const onPageChange = (newPage: number) => {
    if (newPage !== page) {
      onHandlePage(newPage);
    }
  };
  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  const lastPage = paginationRange?.[paginationRange.length - 1];

  return (
    <div className="mb-5 mt-3">
      <ul className="flex items-center justify-end">
        <li className="pt-2">
          <Button onClick={onPrevious} type="button" disabled={page === 1}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right rotate-180"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </Button>
        </li>
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === -1) {
            return (
              <li key={`dots-${index}`}>
                <button>
                  <span>....</span>
                </button>
              </li>
            );
          }

          return (
            <li className="ml-3 first:ml-0" key={pageNumber}>
              <Button
                type="button"
                onClick={() => onPageChange(pageNumber ?? 0)}
                disabled={page === pageNumber}
              >
                <span
                  className={`w-7 h-7 text-s flex items-center justify-center rounded-full ${
                    page === pageNumber ? "bg-primary text-white" : ""
                  }`}
                >
                  {pageNumber}
                </span>
              </Button>
            </li>
          );
        })}

        <li className="ml-3 first:ml-0 pt-2">
          <Button type="button" disabled={page === lastPage} onClick={onNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
