import React from "react";
import ListEmployees from "../../pages/business/ListEmployees";
import ViewTimesheet from "../../pages/business/ViewTimesheet";

const Login = React.lazy(() => import("../../pages/auth/Login"));
const ForgotPassword = React.lazy(
  () => import("../../pages/auth/ForgotPassword")
);
const VerifyOTP = React.lazy(() => import("../../pages/auth/VerifyOTP"));
const SetPassword = React.lazy(() => import("../../pages/auth/ResetPassword"));
const XeroConnect = React.lazy(() => import("../../pages/auth/XeroConnect")); //Xero

const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"));
const UserDetails = React.lazy(() => import("../../pages/user/UserDetails"));
const Users = React.lazy(() => import("../../pages/user/User"));
const Cms = React.lazy(() => import("../../pages/cms/Cms"));
const UserForm = React.lazy(() => import("../../pages/user/userForm"));
const Chat = React.lazy(() => import("../../pages/chat/Chat"));
const Post = React.lazy(() => import("../../pages/post/Post"));
const PostDetails = React.lazy(() => import("../../pages/post/PostDetails"));
const PostForm = React.lazy(() => import("../../pages/post/PostForm"));
const WelcomeCMS = React.lazy(() => import("../../pages/cms/WelcomeCMS"));
const ProfileSetupCMS = React.lazy(
  () => import("../../pages/cms/ProfileSetupCMS")
);

const Notification = React.lazy(
  () => import("../../components/notificationComponent/notification")
);
const Business = React.lazy(() => import("../../pages/business/Business"));
const BusinessDetails = React.lazy(
  () => import("../../pages/business/BusinessDetails")
);
const AddEditBusiness = React.lazy(
  () => import("../../pages/business/AddEditBusiness")
);
const Agreements = React.lazy(
  () => import("../../pages/agreements/Agreements")
);
const Payslips = React.lazy(() => import("../../pages/payslips/Payslips"));
const PayslipDetails = React.lazy(
  () => import("../../pages/payslips/PayslipDetails")
);

export const AuthRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/verify-otp", component: <VerifyOTP /> },
  { path: "/reset-password", component: <SetPassword /> },
];

export const privateRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/user", component: <Users /> },
  { path: "/user-details/:id", component: <UserDetails /> },
  { path: "/edit-user/:id", component: <UserForm /> },
  { path: "/add-user", component: <UserForm /> },
  { path: "/terms&condition", component: <Cms /> },
  { path: "/privacy-policies", component: <Cms /> },
  { path: "/welcome", component: <WelcomeCMS /> },
  { path: "/profile-setup", component: <ProfileSetupCMS /> },
  { path: "/chats", component: <Chat /> },
  { path: "/post", component: <Post /> },
  { path: "/notification", component: <Notification /> },
  { path: "/business", component: <Business /> },
  { path: "/business/:id", component: <BusinessDetails /> },
  { path: "/add-business", component: <AddEditBusiness /> },
  { path: "/edit-business/:id", component: <AddEditBusiness /> },
  { path: "/post-details/:id", component: <PostDetails /> },
  { path: "/add-post", component: <PostForm /> },
  { path: "/edit-post/:id", component: <PostForm /> },
  { path: "/connect-xero", component: <XeroConnect /> },
  { path: "/list-employees/:id", component: <ListEmployees /> },
  { path: "/view-timesheet", component: <ViewTimesheet /> },
  { path: "/user-agreements", component: <Agreements /> },
  { path: "/view-timesheet/:userId/:jobId", component: <ViewTimesheet /> },
  { path: "/payslip", component: <Payslips /> },
  { path: "/payslip/:id", component: <PayslipDetails /> },
];

export const RoutesPath = [
  {
    path: "/",
    name: "Dashboard",
    component: <Dashboard />,
  },
];
