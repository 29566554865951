import React, { useEffect, useState } from "react";
import { AuthRoutes, privateRoutes } from "./routes/Routers";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokenSelector } from "../redux/slices/authSlice";

import AuthLayOut from "../defaultLayout/AuthLayout";
import LayOut from "../defaultLayout";

const NotFound = React.lazy(() => import("../pages/notFound/NotFound"));

const RouterComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = useSelector(tokenSelector);
  const [temp, setTemp] = useState(false);

  useEffect(() => {
    setTemp(!temp);
    !token &&
      !AuthRoutes.find((val) => val.path === pathname) &&
      navigate("/login");
  }, [token, pathname, navigate]);

  return (
    <Routes>
      {token && (
        <Route path="/" element={<LayOut />}>
          {privateRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            );
          })}
        </Route>
      )}
      {!token && (
        <Route path="/" element={<AuthLayOut />}>
          {AuthRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>
      )}
      {token && !AuthRoutes.find((val) => val.path === pathname) && (
        <Route path="*" element={<LayOut />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      )}
    </Routes>
  );
};

export default RouterComponent;
