import { FC } from "react";
import Button from "../formComponents/Button";
import approveModal from "../../assets/images/approve-icon.svg";
import close from "../../assets/images/close.svg";

interface ApproveModalProps {
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
}

export const ApprovalModal: FC<ApproveModalProps> = ({
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  title = "Approve Timesheet",
  message = "Are you sure you want to delete the User ?",
}) => {
  return (
    <div
      className={`fixed  top-0 right-0 bottom-0  left-0 z-[9999] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full bg-black/40 ${
        isOpen ? " " : "hidden"
      }`}
    >
      <div className="relative w-full h-screen max-w-lg max-h-full m-auto flex items-center justify-center ">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t">
            <h3 className="text-xl font-semibold text-primary">{title}</h3>

            <Button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              onClick={onClose}
              disabled={isLoading}
            >
              <>
                <img src={close} alt="Delete" />

                <span className="sr-only">Close modal</span>
              </>
            </Button>
          </div>

          <div className="p-6 space-y-6 text-center">
            <span className="flex justify-center items-center w-52 h-52 bg-primary/5 rounded-full mx-auto">
              <span className="stroke-primary">
                <img src={approveModal} alt="Delete" />
              </span>
            </span>

            <p className="text-base leading-relaxed text-gray-500">{message}</p>
          </div>

          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
            <div className="mx-auto">
              <Button
                type="button"
                className="text-white bg-primary hover:bg-primary/70 focus:ring-4 focus:outline-none focus:ring-primary/20 font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-2"
                onClick={onConfirm}
                disabled={isLoading}
              >
                Yes
              </Button>

              <Button
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary/20 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 mx-2"
                onClick={onClose}
                disabled={isLoading}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
