import React from "react";

interface IButton {
  type: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: JSX.Element | string;
  className?: string;
  loader?: boolean;
  disabled?: boolean;
}

const Button: React.FC<IButton> = (props: IButton) => {
  return (
    <>
      {!props.loader ? (
        <button
          type={props.type}
          onClick={props.onClick}
          className={props.className}
          disabled={props.disabled}
        >
          {props.children}
        </button>
      ) : (
        <button type={props.type} className={props.className} disabled>
          Loading...
        </button>
      )}
    </>
  );
};

export default Button;
